import { useCallback, useEffect, useRef, useState } from 'react'

import { CellProps, Column } from 'react-table'
import { useNavigate } from 'react-router-dom'

import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useToggle } from 'shared/hooks'

import {
  Button,
  ContainerScreen,
  Input,
  Table,
  TableRef,
  Tags,
} from 'components'

import { Filters } from '../../components/Filter/Filters'

import { ReactComponent as InspectIcon } from 'assets/svg/listInspectIcon.svg'

import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'

import { AttendancePolicyFilter } from 'domains/attendancePolicy/types'
import { Pagination, Result } from 'services/types'
import { AttendancePolicyQueryResponse } from 'services/attendancePolicy/types'
import { AttendancePolicyDriver } from 'services/attendancePolicy'
import { maskedDateTime } from 'utilities/date'

import { OccurrenceTypeTitle } from 'domains/occurrence/data/occurrence'

import { attendancePolicyFiltersSchema } from 'domains/attendancePolicy/schema'

import styles from './AttendancePolicy.module.scss'
import { buildPath, paths } from 'routes'

const columns: Column<AttendancePolicyQueryResponse>[] = [
  {
    id: 'name',
    Header: 'Nome',
    accessor: (attendancePolicy) => attendancePolicy.name,
  },
  {
    id: 'service',
    Header: 'Serviço',
    accessor: (attendancePolicy) => attendancePolicy.serviceTypeName,
  },
  {
    id: 'occurrence',
    Header: 'Ocorrência',
    accessor: (attendancePolicy) =>
      OccurrenceTypeTitle[attendancePolicy.occurrenceTypeName],
  },
  {
    id: 'coverage',
    Header: 'Abrangência',
    accessor: (attendancePolicy) => attendancePolicy,
    Cell: (cell: CellProps<AttendancePolicyQueryResponse>) => {
      const attendancePolicy = cell.value as AttendancePolicyQueryResponse
      if (!attendancePolicy.coverages.length) return 'Geral'
      return (
        <div>
          <Tags
            keyId={attendancePolicy.id}
            className={styles.firstTag}
            keyLabel="user-profiles"
            data={attendancePolicy.coverages}
          />
        </div>
      )
    },
  },
  {
    id: 'createdAt',
    Header: 'Cadastrado em',
    accessor: (attendancePolicy) => maskedDateTime(attendancePolicy.createdAt),
  },
]

const AttendancePolicy = () => {
  const { getFilters, setFilters } =
    usePersistentFilters<AttendancePolicyFilter>('attendancePolicy')

  const navigate = useNavigate()

  const filterSheetProps = useToggle()
  const loader = useToggle()

  const tableRef = useRef<TableRef>(null)

  const [hasFilter, setHasFilter] = useState(false)

  const form = useForm<AttendancePolicyFilter>({
    resolver: joiResolver(attendancePolicyFiltersSchema),
    mode: 'onChange',
    defaultValues: getFilters(),
  })

  const { watch, register, unregister, getValues, reset, setValue } = form

  const name = getValues('name')
  const serviceType = getValues('serviceType')
  const occurrenceType = getValues('occurrenceType')
  const active = getValues('active')
  const hasSelectors = getValues('hasSelectors')
  const customer = getValues('customer')
  const account = getValues('account')
  const createdFrom = watch('createdFrom')
  const createdTo = watch('createdTo')

  const fetchAttendancePolicies = useCallback(
    async (pagination: Pagination) => {
      loader.show()
      let attendPolicies: Result<AttendancePolicyQueryResponse> = {
        data: [],
        totalElements: 0,
      }

      if (name) {
        reset()
        setValue('name', name)
        setFilters(watch())
      }

      try {
        attendPolicies = await AttendancePolicyDriver.query({
          ...(name && { name }),
          ...(serviceType && { serviceTypeId: serviceType.id }),
          ...(occurrenceType && { occurrenceTypeId: occurrenceType.id }),
          ...(customer && !account ? { customerId: customer.id } : undefined),
          ...(account ? { accountId: account.id } : undefined),
          ...(createdFrom && { createdFrom }),
          ...(createdTo && { createdTo }),
          sort: 'createdAt,desc',
          active,
          hasSelectors,
          page: Math.round(pagination.offset / pagination.recordsPerPage),
          size: pagination.recordsPerPage,
        })
      } finally {
        loader.hide()
      }

      return attendPolicies
    },
    [
      name,
      serviceType,
      occurrenceType,
      active,
      hasSelectors,
      customer,
      account,
      createdFrom,
      createdTo,
      loader,
      reset,
      setFilters,
      setValue,
      watch,
    ],
  )

  useEffect(() => {
    if (!filterSheetProps.isVisible) {
      setHasFilter(
        Object.values(watch())
          .slice(1, 7)
          .some((value) => value),
      )
    }
  }, [form, filterSheetProps, watch])

  useEffect(() => {
    register('serviceType')
    register('occurrenceType')
    register('customer')
    register('account')
    register('active')
    register('hasSelectors')
    register('createdFrom')
    register('createdTo')

    return () => {
      unregister('serviceType')
      unregister('occurrenceType')
      unregister('hasSelectors')
      unregister('customer')
      unregister('account')
      unregister('active')
      unregister('createdFrom')
      unregister('createdTo')
    }
  }, [register, unregister])

  return (
    <>
      <FormProvider {...form}>
        <Filters
          isVisible={filterSheetProps.isVisible}
          onClose={filterSheetProps.hide}
          onApplyFilters={() => {
            if (name) {
              form.setValue('name', undefined)
            }
            setFilters(watch())
            tableRef.current?.handleFetchPage()
            filterSheetProps.hide()
            navigate({ search: '?page=1' })
          }}
        />

        <ContainerScreen
          clickable={!filterSheetProps.isVisible}
          title="Regras de atendimento"
          renderFilters={
            <form
              onSubmit={(event) => {
                event.preventDefault()
                tableRef.current?.handleFetchPage()
              }}
            >
              <section className={styles.innerWrapper}>
                <Input
                  label="Nome"
                  placeholder="Digite o nome da regra"
                  autoComplete="off"
                  disabled={filterSheetProps.isVisible}
                  {...register('name')}
                />
                <Button
                  buttonTitle="Pesquisar"
                  type="primary"
                  htmlType="submit"
                />
                <Button
                  buttonTitle="Nova regra"
                  type="secondary"
                  icon={PlusIcon}
                  width="143px"
                  onClick={() =>
                    navigate(paths.configuration.attendancePolicy.create)
                  }
                />
                <Button
                  buttonTitle={
                    hasFilter ? 'Editar filtros' : 'Adicionar filtros'
                  }
                  type="tertiary"
                  icon={FiltersIcon}
                  onClick={filterSheetProps.show}
                />
              </section>
            </form>
          }
        >
          <Table
            innerRef={tableRef}
            columns={columns}
            paginated
            actions={[
              {
                label: 'Visualizar',
                Icon: InspectIcon,
                handler: (attendancePolicy: AttendancePolicyQueryResponse) =>
                  navigate(
                    buildPath({
                      path: paths.configuration.attendancePolicy.update,
                      params: { attendancePolicyId: attendancePolicy.id },
                    }),
                  ),
              },
            ]}
            resetPagination={false}
            fetcher={fetchAttendancePolicies}
          />
        </ContainerScreen>
      </FormProvider>
    </>
  )
}

export default AttendancePolicy
